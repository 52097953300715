<template>
  <div>
    <!-- <a-space :size="16" style="margin: 8px 0 8px 16px;">
      <a-checkbox>全选/全部选</a-checkbox>
      <a-checkbox @change="expendChange">展开/折叠</a-checkbox>
    </a-space> -->
    <!--  :expanded-keys="expandedKeys" -->
    <a-tree
      v-model="checkedKeys"
      :checkable="true"
      :check-strictly="true"
      :selectable="false"
      :disabled="disabled"
      :auto-expand-parent="true"
      :tree-data="treeData"
      @check="onCheck"
      style="padding-left: 24px;"
    >
      <template slot="title" slot-scope="record">
        <slider-menu-icon style="font-size: 16px;" v-if="record.meta && record.meta.icon" :icon="record.meta.icon"></slider-menu-icon>
        {{ record.title }}
      </template>
    </a-tree>
  </div>
</template>

<script>
import SliderMenuIcon from '@/components/icon/SliderMenuIcon'

export default {
  name: 'MenuTree',
  props: {
    treeData: {
      type: Array
    },
    customChecked: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SliderMenuIcon
  },
  computed: {
    treeIds () {
      return this.getIds(this.treeData, [])
    },
    customIds () {
      return this.getIds(this.customChecked, [], 'id')
    }
  },
  data () {
    return {
      expandedKeys: [],
      checkedKeys: []
    }
  },
  mounted () {
    this.filterCustomChecked()
  },
  methods: {
    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck (checkedKeys) {
      this.checkedKeys = checkedKeys.checked
      this.$emit('selectPageRoutes', this.checkedKeys)
    },
    filterCustomChecked () {
      if (this.customChecked.findIndex(item => item.key === '*') !== -1) this.checkedKeys = this.treeIds
      else this.checkedKeys = this.customIds.filter(id => this.treeIds.findIndex(v => v === id) !== -1)
      this.$emit('selectPageRoutes', this.checkedKeys)
    },
    getIds (data, arr, key = 'key') {
      const a = data.reduce((prev, cur) => {
        prev.push(cur[key])
        if (cur.children && cur.children.length) {
          return this.getIds(cur.children, prev)
        }
        return prev
      }, arr)
      return a
    }
  }
}
</script>
