<template>
  <div>
    <a-checkbox :disabled="disabled" :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange" style="margin-right: 40px; border-right: 1px solid #e5e5e5; padding-right: 40px;">
      全选
    </a-checkbox>
    <a-checkbox-group
      v-model="checkedList"
      :name="data.name"
      :disabled="disabled"
      :options="data.children"
      @change="onChange"
    />
  </div>
</template>

<script>

export default {
  name: 'ApiCheckGroup',
  props: {
    data: {
      type: Object,
      required: true
    },
    selected: {
      type: Array,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      plainOptions: this.data.children.map(item => item.id)
    }
  },
  mounted () {
    if (this.selected.length && this.data.children.length) {
      if (this.selected.findIndex(i => i.name === '全部接口') !== -1) {
        this.checkedList = this.data.children.map(item => item.id)
        this.checkAll = true
        this.indeterminate = false
        this.$emit('selected', { data: this.checkedList, name: this.data.name })
      } else {
        const all = this.data.children
        all.forEach(item => {
          if (this.selected.findIndex(v => v.id === item.id) !== -1) {
            this.checkedList.push(item.id)
            this.indeterminate = true
          }
        })
        if (this.checkedList.length === this.data.children.length) {
          this.checkAll = true
          this.indeterminate = false
        }
        this.$emit('selected', { data: this.checkedList, name: this.data.name })
      }
    }
  },
  methods: {
    onChange (checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length
      this.checkAll = checkedList.length === this.plainOptions.length
      this.$emit('selected', { data: this.checkedList, name: this.data.name })
    },
    onCheckAllChange (e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
      this.$emit('selected', { data: this.checkedList, name: this.data.name })
    }
  }
}
</script>
