<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isView ? '角色查看' : isUpdate ? '角色编辑' : '角色创建'"
    :visible="visible"
    :width="1000"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="角色名称" prop="name">
        <a-input v-model="form.name" :max-length="128" :disabled="isView"></a-input>
      </a-form-model-item>
      <a-form-model-item label="页面配置" prop="page_permission_ids">
        <a-tabs type="card" @change="handleChange" size="small" v-if="pagePermissions.length">
          <a-tab-pane v-for="menu in DEFAULT_MENU_MODULES" :key="menu.key">
            <template #tab>
              <header-menu-icon style="font-size: 16px;" v-if="menu.headerIcon" :icon="menu.headerIcon"></header-menu-icon>
              {{ menu.name }}
            </template>
            <menu-tree :tree-data="getTableData(menu.key)" :custom-checked="updateInfo.page_permissions" @selectPageRoutes="v => getSelected(v, menu.key)" :disabled="isView"></menu-tree>
          </a-tab-pane>
        </a-tabs>
      </a-form-model-item>
      <a-form-model-item label="操作配置" prop="api_permission_ids">
        <div style="max-height: 800px; overflow: auto; border: 1px solid #e5e5e5; padding: 12px 16px; border-radius: 8px;">
          <div v-for="permission in formatPermission" :key="permission.key" style="line-height: 36px; border-bottom: 1px solid #e5e5e5; ">
            <span style="display: inline-block; min-width: 200px; font-weight: 500; border-right: 1px solid #e5e5e5; margin-right: 40px;">{{ permission.name }}</span>
            <api-check-group :data="permission" style="display: inline-block;" @selected="getApiSelected" :selected="updateInfo.api_permissions"  :disabled="isView"></api-check-group>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item
        v-if="!isView"
        :wrapper-col="{ span: 20, offset: 4 }"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPagePermission, getApiPermission } from '@/api/user'
import { createRole, getRole, updateRole } from '@/api/role'
import { DEFAULT_MENU_MODULES } from '@/utils/const'
import MenuTree from './MenuTree.vue'
import HeaderMenuIcon from '@/components/icon/HeaderMenuIcon'
import { menuRoutes } from '@/router/routes'
import ApiCheckGroup from './ApiCheckGroup'

export default {
  name: 'InputDrawer',
  components: {
    MenuTree,
    HeaderMenuIcon,
    ApiCheckGroup
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      loading: false,
      form: {
        id: '',
        name: '',
        page_permission_ids: [],
        api_permission_ids: []
      },
      rules: {
        name: [
          {
            message: '请输入角色名称',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      selectedPageRoutes: {}, // 用户已选
      DEFAULT_MENU_MODULES,
      pagePermissions: [], // 获取的全部
      apiPermissions: [], // 获取的全部，
      formatPermission: [],
      selectedApi: {},
      updateInfo: {
        page_permissions: [],
        api_permissions: []
      },
      isView: false
    }
  },
  computed: {
    ...mapGetters(['menuRoutes'])
  },
  methods: {
    show (id, isView = false) {
      if (isView) this.isView = true
      else this.isView = false
      getPagePermission().then(res => {
        this.pagePermissions = this.filterRoutes(menuRoutes, res.data.data)
      })
      getApiPermission().then(res => {
        this.apiPermissions = res.data.data
        this.formatPermission = this.getFormatApiPermissions(res.data.data)
      })
      if (id) {
        this.isUpdate = true
        getRole(id).then(res => {
          this.form = {
            id,
            name: res.data.name,
            page_permission_ids: [],
            api_permission_ids: []
          }
          this.updateInfo = {
            page_permissions: res.data.page_permissions,
            api_permissions: res.data.api_permissions
          }
        }).finally(() => {
          this.visible = true
        })
      } else {
        this.visible = true
      }
    },
    closeDrawer () {
      this.visible = false
      this.form = {
        id: '',
        name: '',
        page_permission_ids: [],
        api_permission_ids: []
      }
      this.updateInfo = {
        page_permissions: [],
        api_permissions: []
      }
      this.selectedPageRoutes = {}
      this.apiPermissions = []
      this.pagePermissions = []
      this.formatPermission = []
      this.selectedApi = {}
      this.isUpdate = false
      this.loading = false
      this.$refs.form.resetFields()
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            name: this.form.name
          }
          const v = Object.values(this.selectedPageRoutes)
          params.page_permission_ids = []
          if (v.length) {
            v.forEach(ids => {
              params.page_permission_ids.push(...ids)
            })
            if (params.page_permission_ids.length === (this.pagePermissions.length - 1)) {
              params.page_permission_ids = [this.pagePermissions.find(item => item.key === '*').id]
            }
          }

          const v1 = Object.values(this.selectedApi)
          params.api_permission_ids = []
          if (v1.length) {
            v1.forEach(ids => {
              params.api_permission_ids.push(...ids)
            })
            if (params.api_permission_ids.length === (this.apiPermissions.length - 1)) {
              params.api_permission_ids = [this.apiPermissions.find(item => item.name === '全部接口').id]
            }
          }

          if (this.isUpdate) {
            updateRole(this.form.id, params).then(res => {
              this.$message.success(res.message)
              this.$emit('ok')
            }).finally(() => {
              this.loading = true
              this.closeDrawer()
            })
          } else {
            createRole(params).then(res => {
              this.$message.success(res.message)
              this.$emit('ok')
            }).finally(() => {
              this.loading = true
              this.closeDrawer()
            })
          }
        }
      })
    },
    getTableData (key) {
      const routes = this.pagePermissions.filter(item => item.path.split('/')[0] === key)
      if (this.updateInfo.page_permissions.length) {
        this.getDefaultPage(routes, key)
      }
      return routes
    },
    getDefaultPage (routes, key) {
      routes.forEach(route => {
        if (this.updateInfo.page_permissions.findIndex(item => item.name === '全部页面') === -1) {
          if (this.updateInfo.page_permissions.findIndex(item => route.key === item.id) !== -1) {
            if (!Array.isArray(this.selectedPageRoutes[key])) this.selectedPageRoutes[key] = []
            if (this.selectedPageRoutes[key].findIndex(id => id === route.key) === -1) this.selectedPageRoutes[key].push(route.key)
          }
          if (route.children && route.children.length) {
            this.getDefaultPage(route.children, key)
          }
        } else {
          if (!Array.isArray(this.selectedPageRoutes[key])) this.selectedPageRoutes[key] = []
          if (this.selectedPageRoutes[key].findIndex(id => id === route.key) === -1) this.selectedPageRoutes[key].push(route.key)
          if (route.children && route.children.length) {
            this.getDefaultPage(route.children, key)
          }
        }
      })
    },
    filterRoutes (routes, permissionRoutes) {
      return routes.filter(route => {
        const index = permissionRoutes.findIndex(pageRoute => route.name === pageRoute.key)
        if (index !== -1) {
          route.key = permissionRoutes[index].id
          route.title = permissionRoutes[index].name
          if (route.children && route.children.length) {
            route.children = this.filterRoutes(route.children, permissionRoutes)
          }
          return true
        } else {
          return false
        }
      })
    },
    getSelected (v, key) {
      this.selectedPageRoutes[key] = v
      if (this.selectedPageRoutes[key].length === 0) delete this.selectedPageRoutes[key]
    },
    handleChange () {},
    getFormatApiPermissions (apiPermissions) {
      const arr = []
      apiPermissions.forEach(permission => {
        if (permission.name !== '全部接口') {
          const name = permission.name.split('-')
          const index = arr.findIndex(item => item.name === name[0])
          if (index === -1) {
            const obj = {
              name: name[0],
              id: name[0],
              key: name[0],
              children: [
                {
                  name: name[1],
                  id: permission.id,
                  key: permission.key,
                  label: name[1],
                  value: permission.id
                }
              ]
            }
            arr.push(obj)
          } else {
            arr[index].children.push({
              name: name[1],
              id: permission.id,
              key: permission.key,
              label: name[1],
              value: permission.id
            })
          }
        }
      })

      return arr
    },
    getApiSelected (v) {
      this.selectedApi[v.name] = v.data
    }
  }
}
</script>

<style>

</style>
