<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="user"></a-icon>
          <span>角色设置</span>
        </a-space>
      </template>
      <span>角色设置包含角色、权限等设置。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'search', 'leftTool']"
        :refresh="refresh"
         :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <create-button
            v-permission="{action: 'base.role.create', effect: 'disabled'}"
            @click="$refs.inputModal.show()"
          ></create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #name="{ slotProps }">
          <a-tooltip :title="slotProps.text">
            <a @click="$refs.inputModal.show(slotProps.record.id, true)">{{ slotProps.text }}</a>
          </a-tooltip>
        </template>
        <template #apiPermissions="{ slotProps }">
          {{ slotProps.text | getLength }}
        </template>
         <template #pagePermissions="{ slotProps }">
          {{ slotProps.text | getLength }}
        </template>
        <template #operation="{ slotProps }">
          <edit-button
          v-permission="{action: 'base.role.update', effect: 'disabled'}"
            @click="$refs.inputModal.show(slotProps.record.id)"
          ></edit-button>
          <delete-button
          v-permission="{action: 'base.role.delete', effect: 'disabled'}"
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>
    <input-drawer ref="inputModal" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import { deleteRole, getRoleList } from '@/api/role'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import InputDrawer from './modules/InputDrawer.vue'

export default {
  name: 'Role',
  components: {
    DeleteButton,
    EditButton,
    ListLayout,
    InputDrawer,
    CreateButton
  },
  data () {
    return {
      getTableDataFunc: getRoleList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '角色名称',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'api_permissions',
          title: '接口权限',
          width: 100,
          align: 'center',
          scopedSlots: {
            customRender: 'apiPermissions'
          }
        },
        {
          dataIndex: 'page_permissions',
          title: '页面权限',
          width: 100,
          align: 'center',
          scopedSlots: {
            customRender: 'pagePermissions'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'api_permissions',
        'page_permissions',
        'created_at',
        'updated_at'
      ],
      refresh: false,
      tableSelectData: []
    }
  },
  filters: {
    getLength (v) {
      if (v.length && v.find(item => item.name === '全部接口' || item.name === '全部页面')) return '全部'
      return v.length + ' 条'
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteRole(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
